// Цвета
$color-black: #000000;
$color-onyx: #111111;
$color-dark-jungle: #222222;
$color-mine-shaft: #333333;
$color-tundora: #444444;
$color-davys-grey: #555555;
$color-ironside-gray: #666666;
$color-tapa-gray: #777777;
$color-taupe-gray: #888888;
$color-mountain-mist: #999999;
$color-dark-gray: #aaaaaa;
$color-gray: #bbbbbb;
$color-celeste: #cccccc;
$color-light-gray: #dddddd;
$color-eallery: #eeeeee;
$color-white: #ffffff;
$color-yellow: #ffff00;
$color-navy-blue: #0079c1;
$color-prussian-blue: #00426a;
$color-error: #e24831;
$color-carrot-orange: #f39318;
$carrot-orange: #f39318;
$color-lima: #78b226;
$color-grey-chateau: #939597;
$color-orange: #fea300;
$color-malachite: #20bc55;
$color-light-sea-green: #1aa3a7;
$color-forest-green: #2f9f13;
$color-limerick: #95bb20;
$color-la-rioja: #c8d300;
$color-yellow: #ffff00;
$color-star-dust: #9d9d9c;
$color-pink-swan: #b3b2b2;
$color-arsenic: #39424a;
$color-rhino: #444c55;
$color-shuttle-grey: #51626f;
$color-alizarin: #ec1c24;
$color-error: $color-alizarin;
$color-white-smoke: #f2f2f2;
$color-fire-engine-red: #ce1126;
$color-ship-grey: #575756;
$color-oyster-bay: #e1f1f1;
$color-mabel: #c1ecec;
$color-turquoise-blue: #56dce0;
$color-panache: #eaf5e7;
$color-azure: #f0f8f8;
$color-gainsboro: #d9d9d9;
$color-aluminium: #929496;
$color-aubergine: #221e1f;
$color-daisy-bush: #553995;
$color-blue-violet: #6c3ce8;
$color-iris-blue: #12bccd;
$color-radical-red: #f04b54;
$color-crimson: #eb1745;
$color-dark-tangerine: #fdb913;
$color-cobalt: #0059a8;
$color-cardinal: #cb1d46;
$color-aubergine: #262324;
$color-seagull: #16becf;
$color-bright-sun: #ebb730;
$color-blue-gem: #4f3c92;
$color-manatee: #929396;
$color-content-black: #231f20;
$color-mid-gray: #dadada;
$dim-gray: #676767;
$pompadour: #7C1649;
$crimson_2: #E2023A;
$whisper: #e5e5e5;
$silver:#C4C4C4;
$color-jazzberry-jam: #A00F43;

.color-silver {
	color: $silver;
}

.color-dim-gray {
	color: $dim-gray;
}

.color-pompadour {
	color: $pompadour;
}

.color-jazzberry-jam {
	color: $color-jazzberry-jam;
}

.color-crimson_2 {
	color: $crimson_2;
}

.color-black {
	color: $color-black;
}

.color-onyx {
	color: $color-onyx;
}

.color-dark-jungle {
	color: $color-dark-jungle;
}

.color-mine-shaft {
	color: $color-mine-shaft;
}

.color-tundora {
	color: $color-tundora;
}

.color-davys-grey {
	color: $color-davys-grey;
}

.color-ironside-gray {
	color: $color-ironside-gray;
}

.color-tapa-gray {
	color: $color-tapa-gray;
}

.color-taupe-gray {
	color: $color-taupe-gray;
}

.color-mountain-mist {
	color: $color-mountain-mist;
}

.color-dark-gray {
	color: $color-dark-gray;
}

.color-gray {
	color: $color-gray;
}

.color-celeste {
	color: $color-celeste;
}

.color-light-gray {
	color: $color-light-gray;
}

.color-eallery {
	color: $color-eallery;
}

.color-white {
	color: $color-white;
}

.color-yellow {
	color: $color-yellow;
}

.color-navy-blue {
	color: $color-navy-blue;
}

.color-prussian-blue {
	color: $color-prussian-blue;
}

.color-error {
	color: $color-error;
}

.color-lima {
	color: $color-lima;
}

.color-grey-chateau {
	color: $color-grey-chateau;
}

.color-orange {
	color: $color-orange;
}

.color-malachite {
	color: $color-malachite;
}

.color-light-sea-green {
	color: $color-light-sea-green;
}

.color-forest-green {
	color: $color-forest-green;
}

.color-limerick {
	color: $color-limerick;
}

.color-la-rioja {
	color: $color-la-rioja;
}

.color-yellow {
	color: $color-yellow;
}

.color-star-dust {
	color: $color-star-dust;
}

.color-pink-swan {
	color: $color-pink-swan;
}

.color-arsenic {
	color: $color-arsenic;
}

.color-rhino {
	color: $color-rhino;
}

.color-shuttle-grey {
	color: $color-shuttle-grey;
}

.color-alizarin {
	color: $color-alizarin;
}

.color-white-smoke {
	color: $color-white-smoke;
}

.color-fire-engine-red {
	color: $color-fire-engine-red;
}

.color-ship-grey {
	color: $color-ship-grey;
}

.color-oyster-bay {
	color: $color-oyster-bay;
}

.color-mabel {
	color: $color-mabel;
}

.color-turquoise-blue {
	color: $color-turquoise-blue;
}

.color-panache {
	color: $color-panache;
}

.color-azure {
	color: $color-azure;
}

.color-gainsboro {
	color: $color-gainsboro;
}

.color-aluminium {
	color: $color-aluminium;
}

.color-aubergine {
	color: $color-aubergine;
}

.color-daisy-bush {
	color: $color-daisy-bush;
}

.color-iris-blue {
	color: $color-iris-blue;
}

.color-radical-red {
	color: $color-radical-red;
}

.color-crimson {
	color: $color-crimson;
}

.color-dark-tangerine {
	color: $color-dark-tangerine;
}

.color-cobalt {
	color: $color-cobalt;
}

.bg-color-black {
	background-color: $color-black;
}

.bg-color-onyx {
	background-color: $color-onyx;
}

.bg-color-dark-jungle {
	background-color: $color-dark-jungle;
}

.bg-color-mine-shaft {
	background-color: $color-mine-shaft;
}

.bg-color-tundora {
	background-color: $color-tundora;
}

.bg-color-davys-grey {
	background-color: $color-davys-grey;
}

.bg-color-ironside-gray {
	background-color: $color-ironside-gray;
}

.bg-color-tapa-gray {
	background-color: $color-tapa-gray;
}

.bg-color-taupe-gray {
	background-color: $color-taupe-gray;
}

.bg-color-mountain-mist {
	background-color: $color-mountain-mist;
}

.bg-color-dark-gray {
	background-color: $color-dark-gray;
}

.bg-color-gray {
	background-color: $color-gray;
}

.bg-color-celeste {
	background-color: $color-celeste;
}

.bg-color-light-gray {
	background-color: $color-light-gray;
}

.bg-color-eallery {
	background-color: $color-eallery;
}

.bg-color-white {
	background-color: $color-white;
}

.bg-color-yellow {
	background-color: $color-yellow;
}

.bg-color-navy-blue {
	background-color: $color-navy-blue;
}

.bg-color-prussian-blue {
	background-color: $color-prussian-blue;
}

.bg-color-error {
	background-color: $color-error;
}

.bg-color-lima {
	background-color: $color-lima;
}

.bg-color-grey-chateau {
	background-color: $color-grey-chateau;
}

.bg-color-orange {
	background-color: $color-orange;
}

.bg-color-malachite {
	background-color: $color-malachite;
}

.bg-color-light-sea-green {
	background-color: $color-light-sea-green;
}

.bg-color-forest-green {
	background-color: $color-forest-green;
}

.bg-color-limerick {
	background-color: $color-limerick;
}

.bg-color-la-rioja {
	background-color: $color-la-rioja;
}

.bg-color-yellow {
	background-color: $color-yellow;
}

.bg-color-star-dust {
	background-color: $color-star-dust;
}

.bg-color-pink-swan {
	background-color: $color-pink-swan;
}

.bg-color-arsenic {
	background-color: $color-arsenic;
}

.bg-color-rhino {
	background-color: $color-rhino;
}

.bg-color-shuttle-grey {
	background-color: $color-shuttle-grey;
}

.bg-color-alizarin {
	background-color: $color-alizarin;
}

.bg-color-white-smoke {
	background-color: $color-white-smoke;
}

.bg-color-fire-engine-red {
	background-color: $color-fire-engine-red;
}

.bg-color-ship-grey {
	background-color: $color-ship-grey;
}

.bg-color-oyster-bay {
	background-color: $color-oyster-bay;
}

.bg-color-mabel {
	background-color: $color-mabel;
}

.bg-color-turquoise-blue {
	background-color: $color-turquoise-blue;
}

.bg-color-panache {
	background-color: $color-panache;
}

.bg-color-azure {
	background-color: $color-azure;
}

.bg-color-gainsboro {
	background-color: $color-gainsboro;
}

.bg-color-aluminium {
	background-color: $color-aluminium;
}

.bg-color-aubergine {
	background-color: $color-aubergine;
}

.bg-color-daisy-bush {
	background-color: $color-daisy-bush;
}

.bg-color-iris-blue {
	background-color: $color-iris-blue;
}

.bg-color-radical-red {
	background-color: $color-radical-red;
}

.bg-color-crimson {
	background-color: $color-crimson;
}

.bg-color-dark-tangerine {
	background-color: $color-dark-tangerine;
}

.bg-color-cobalt {
	background-color: $color-cobalt;
}
