@charset "utf-8";
// Переменные
//
// Переменные SASS описаны в файле [sass/_vars.scss](../../sass/_vars.scss)
//
// Styleguide 2

// Путь к папке
$imagesFolder: 'static';

//Параметры сайта
$debug: false;
$responsive: true;       // адаптивность да/нет
$site-width: 1800px;       // средняя ширина
$site-min-width: 320px;     // минимальная ширина страницы
$site-max-width: $site-width;     // максимальная ширина
$breakpoint-xs: 510px;
$breakpoint-sm: 798px;
$breakpoint-md: 1054px;
$breakpoint-lg: 1270px;

// Параметры брекпоинтов сетки
$breakpoint-tiny: 320px !default;
$breakpoint-small: $breakpoint-xs;
$breakpoint-mobile: $breakpoint-sm;
$breakpoint-tablet: $breakpoint-md;
$breakpoint-desktop: $breakpoint-lg;

//параметры сетки
$grid-columns: 12;
$grid-gutter-width: 15px;
$grid-legacy-support: true;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$input-height: 30px;
$input-padding: 0 0.3em;
$textarea-padding: 0 0.3em;
$btn--padding-vertical: 0.667em; // отступы по сверху и снизу кнопок
$btn--padding-horizontal: 0.567em; // отступы по краям кнопок
$checkbox-size: $input-height;
$default-font-size: 18;     // number of pixels, without units - default browser font size (16)
$base-font-size: $default-font-size * 1px;      // размер текста по умолчанию для текста
$base-line-height: (21 / 18);   // межстрочное расстояние

//Размеры областей страницы
$header-height: 130px;
$left-col-width: 225px;
$center-col-width: 563px;
$right-col-width: 235px;
$footer-height: 100px;
$cursorProperty: default, crosshair, help, move, pointer, progress, text, wait, n-resize, ne-resize, e-resize, se-resize, s-resize, sw-resize, w-resize, nw-resize;

//Menu items equal width
/*
  Если элементы первого уровня равны по ширине,
  т.е. ширина будет указана в процентах относительно ширины родительского контейнера,
  ставим true, в противном случае false.
*/
$menu-equal-width: true;

// List marker size
$list-marker-size: 0.323em;

// Шрифт по умолчанию для прогрессивной загрузки
$default-font-family: sans-serif;
// Кастомный шрифт, используемый на проекте
$custom-font-family: FuturaNew;
