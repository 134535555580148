@charset "UTF-8";

nav,
button,
.b-accordion__button::before,
.b-accordion__button::after {
	display: none !important;
}

.b-footnotes,
.b-accordion__article,
.b-bod--animated-blocks {
	display: block;
}

.b-accordion__button,
.b-accordion__article,
.b-footer {
	background: none;
}

main.content {
	width: 100%;
}

.b-copyright {
	page-break-after: always;
}

.b-accordion__section {
	page-break-inside: auto;
}

p {
	widows: 4;
	orphans: 3;
}

.b-footnotes {
	font-size: 0.9em;
}

.b-tooltip-number {
	display: inline;
}

.b-tabs__article {
	position: relative !important;
	height: 100% !important;
	left: 0 !important;
	top: 0 !important;
}

.b-title {
	top: 27px;
	left: 300px;
	font-weight: bold;
}

.b-logo {
	bottom: inherit;
	top: 13px;
}

.b-layout-wrapper--footer,
.b-layout-wrapper--header {
	display: none;
}

.b-cookie-law {
	display: none;
}

.b-indicator__value--print-cloned {
	display: none !important;
}

.b-indicator__value--print-block {
	display: block !important;
}

.b-indicator__value--print-inline-flex {
	display: inline-flex !important;
}

.b-indicator__value--print-flex {
	display: flex !important;
}

.b-indicator__value--print-table {
	display: table !important;
}

.b-indicator__value--print-table-cell {
	display: table-cell !important;
}

.b-indicator__value--print-inline-block {
	display: inline-block !important;
}
