.l-layout-wrapper {
	.b-table[data-visible-rows] tr {
		display: table-row !important;
	}

	.lazyload,
	.lazyloading {
		opacity: 1 !important;
	}

	table {
		position: static !important;
		opacity: 1 !important;
		visibility: visible !important;
	}

	.b-fixed-head {
		display: none !important;
	}

	.l-footer__nav {
		display: none;
	}

	.b-bod--gallery {
		page-break-before: always;
	}

	.b-bod--gallery .b-bod-expandable-content {
		page-break-after: always;
	}

	.inset {
		color: $base-text-color;

		figcaption {
			color: $base-text-color !important;
		}

		ul li:before {
			border-color: transparent transparent transparent $base-text-color !important;
		}
	}
}
